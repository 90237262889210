import type { RouteRecordRaw } from 'vue-router'

import { Permission } from '@/constants/permission.enum'
import { permissionMiddleware } from '@/middleware/permission.middleware'
import ROUTE_NAME from '@/router/routeName'

export const todoRoutes: RouteRecordRaw[] = [
  {
    name: ROUTE_NAME.TODO.INDEX,
    beforeEnter: permissionMiddleware([
      Permission.TODO_READ,
    ]),
    path: '/todos',
    component: () => import('@/modules/todo/views/TodoView.vue'),
    redirect: { name: ROUTE_NAME.TODO.OPEN },
    children: [
      {
        name: ROUTE_NAME.TODO.OPEN,
        path: 'open',
        component: () => import('@/modules/todo/views/TodoOpenView.vue'),
      },
      {
        name: ROUTE_NAME.TODO.CLOSED,
        path: 'closed',
        component: () => import('@/modules/todo/views/TodoCompletedView.vue'),
      },
      {
        name: ROUTE_NAME.TODO.FOLLOW_UP,
        path: 'follow-up',
        component: () => import('@/modules/todo/views/TodoFollowUpView.vue'),
      },
    ],
  },
]
