import type { RouteRecordRaw } from 'vue-router'

import { Permission } from '@/constants/permission.enum'
import { permissionMiddleware } from '@/middleware/permission.middleware'
import ROUTE_NAME from '@/router/routeName'

export const claimsRoutes: RouteRecordRaw[] = [
  {
    name: ROUTE_NAME.CLAIMS.INDEX,
    beforeEnter: permissionMiddleware([
      Permission.CLAIM_READ,
    ]),
    path: '/claims',
    component: () => import('@/modules/claims/views/ClaimsView.vue'),
  },
  {
    name: ROUTE_NAME.CLAIMS.DETAIL.INDEX,
    beforeEnter: permissionMiddleware([
      Permission.CLAIM_READ,
    ]),
    path: '/claims/:uuid',
    component: () => import('@/modules/claims/views/ClaimsDetailView.vue'),
  },
  {
    name: ROUTE_NAME.CLAIMS.CONCEPT.INDEX,
    beforeEnter: permissionMiddleware([
      Permission.CLAIM_CREATE,
    ]),
    path: '/claims/concept/:uuid',
    component: () => import('@/modules/claims/views/ClaimsConceptView.vue'),
  },
]
