import type { RouteRecordRaw } from 'vue-router'

import { accidentsRoutes } from '@/modules/accidents/routes/accidents.routes'
import { anomaliesRoute } from '@/modules/anomalies/routes/anomalies.route'
import { referralPremiumRoutes } from '@/modules/application-premium/routes/referralPremiums.routes'
import { AuthenticationRoutes } from '@/modules/authentication/routes/authentication.routes'
import { claimsRoutes } from '@/modules/claims/routes/claims.routes'
import { compensationsRoutes } from '@/modules/compensations/routes/compensations.routes'
import { customerRoutes } from '@/modules/customer/routes/customer.routes.ts'
import { dashboardRoutes } from '@/modules/dashboard/routes/dashboard.routes'
import { debtorsRoutes } from '@/modules/debtors/routes/debtors.routes'
import { employeesRoutes } from '@/modules/employees/routes/employees.routes'
import { logbooksRoutes } from '@/modules/logbooks/routes/logbooks.routes'
import { matchingRoutes } from '@/modules/matching/routes/matching.routes.ts'
import { newsRoutes } from '@/modules/news/routes/news.routes'
import { remittanceRoutes } from '@/modules/remittance/routes/remittance.routes'
import { settingsRoutes } from '@/modules/settings/routes/settings.routes'
import { subsidiesRoutes } from '@/modules/subsidies/routes/subsidies.routes'
import { todoRoutes } from '@/modules/todo/routes/todo.routes.ts'
import ROUTE_NAME from '@/router/routeName'

const routes: RouteRecordRaw[] = [
  ...AuthenticationRoutes,
  {
    name: ROUTE_NAME.BASE,
    path: '/',
    component: () => import('@/components/layout/AppLayout.vue'),
    meta: { requiresAuth: true },
    redirect: { name: ROUTE_NAME.DASHBOARD },
    children: [
      ...anomaliesRoute,
      ...todoRoutes,
      ...logbooksRoutes,
      ...customerRoutes,
      ...employeesRoutes,
      ...newsRoutes,
      ...remittanceRoutes,
      ...subsidiesRoutes,
      ...referralPremiumRoutes,
      ...debtorsRoutes,
      ...matchingRoutes,
      ...claimsRoutes,
      ...accidentsRoutes,
      ...dashboardRoutes,
      ...compensationsRoutes,
      ...settingsRoutes,
      {
        name: ROUTE_NAME.GLOBAL_SEARCH,
        path: '/global-search',
        component: () => import('@/views/GlobalSearchView.vue'),
      },
    ],
  },
  {
    name: ROUTE_NAME.ERROR,
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/Error404View.vue'),
  },
]

export default routes
