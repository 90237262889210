import type {
  ComputedRef,
  MaybeRefOrGetter,
  Ref,
} from 'vue'

import type { CalendarViewType } from '@/components/calendar/calendarEvent.type'
import type { PaginationOptions } from '@/models/axios/paginationOptions.type'
import type { NoteFilters } from '@/models/note/noteFilter.type'
import type { OriginCompanyFilter } from '@/models/origin-company/originCompany.filter.ts'
import type { AccidentIndexFilter } from '@/modules/accidents/models/index/accidentIndex.filter'
import type { AnomaliesToValidateTableFilter } from '@/modules/anomalies/models/anomaliesToValidateTableItem.model'
import type { ClaimFilter } from '@/modules/claims/models/claim.filter'
import type { CustomerIndexFilter } from '@/modules/customer/models/index/customerIndexTableFilters.type.ts'
import type {
  CustomerRefusedMatchIndexFilters,
} from '@/modules/customer/models/refused-match/customerRefusedMatchIndexFilters.type.ts'
import type {
  CustomerTermsOfServiceFilter,
} from '@/modules/customer/models/terms-of-service/customerContractTermsOfServiceTableFilters'
import type {
  EmployeeDimonaDeclarationIndexFilters,
} from '@/modules/employees/model/dimona-declaration/index/employeeDimonaDeclarationIndexFilters.type'
import type {
  EmployeeEUFilter,
} from '@/modules/employees/model/economic-unemployment/index/employeeEUIndexFilters.type.ts'
import type { EmployeeFilter } from '@/modules/employees/model/employee.filter'
import type {
  EmployeeWageBenefitFilter,
} from '@/modules/employees/model/wage-benefit/index/employeeWageBenefitIndexFilters.type'
import type { MatchFilter } from '@/modules/matching/models/match.filter'
import type { MatchingReservationFilter } from '@/modules/matching/models/reservations/matchingReservation.filter.ts'
import type { NewsFilter } from '@/modules/news/models/newsFilters.type'
import type { JobsIndexFilters } from '@/modules/settings/models/jobs/index/jobsIndexFilter.type.ts'
import type {
  OriginCompanyCustomerIndexFilter,
} from '@/modules/settings/models/origin-companies/customer/index/originCompanyCustomerIndex.filter.ts'
import type {
  OriginCompanyEmployeeIndexFilter,
} from '@/modules/settings/models/origin-companies/employee/index/originCompanyEmployeeIndex.filter.ts'
import type {
  PayrollMergeIndexFilter,
} from '@/modules/settings/models/payroll/merge/index/payrollMergeIndexFilter.type.ts'
import type { StaffFilter } from '@/modules/settings/models/staff/staffTableFilters.type'
import type { TodoIndexFilter } from '@/modules/todo/models/index/todoIndex.filter.ts'

export enum QueryKey {
  ABSENCE_CODES = 'absence-codes',
  ABSENCES = 'absences',

  ACCIDENTS_COUNT = 'accidents-count',
  ACCIDENTS_DETAIL = 'accidents-detail',
  ACCIDENTS_INDEX = 'accidents-index',

  ACTUAL_MATCHES_FOR_CUSTOMER_INDEX = 'actual-matches-for-customer-index',
  ACTUAL_MATCHES_FOR_EMPLOYEE_INDEX = 'actual-matches-for-employee-index',

  ADDRESS_HISTORY = 'address-history',

  ADVANCE_AMOUNT_DETAIL = 'advance-amount-detail',
  ADVANCE_AMOUNTS = 'advance-amounts',

  ANOMALIES_EXCESS = 'anomalies-excess',
  ANOMALIES_SHORTAGES = 'anomalies-shortages',

  ANOMALIES_TO_VALIDATE = 'anomalies-to-validate',
  BAREMA = 'barema',

  BAREMA_CURRENT = 'barema-current',
  BENEFIT_CODE = 'benefit-code',
  BRANCHES = 'branches',
  CHAT_THREAD_MESSAGES = 'chat-thread-messages',
  CLAIMS_COUNT = 'claims-count',
  CLAIMS_DETAIL = 'claims-detail',
  CLAIMS_INDEX = 'claims',
  COMPENSATIONS_ADVANCES = 'compensations-advances',
  COMPENSATIONS_BENEFIT_OVERVIEW = 'compensations-benefit-overview',
  COMPENSATIONS_CALCULATED_DETAIL = 'compensations-calculated-detail',
  COMPENSATIONS_COMBINED_DETAIL = 'compensations-combined-detail',
  COMPENSATIONS_CORRECTIONS = 'compensations-corrections',
  COMPENSATIONS_CORRECTIONS_APPROVED_CHANGES = 'compensations-corrections-approved-changes',
  COMPENSATIONS_CORRECTIONS_APPROVED_DETAIL = 'compensations-corrections-approved-detail',
  COMPENSATIONS_CORRECTIONS_EXPORT = 'compensations-corrections-export',
  COMPENSATIONS_CORRECTIONS_EXPORT_RECORD = 'compensations-corrections-export-record',
  COMPENSATIONS_CORRECTIONS_EXPORT_RECORD_CHANGES = 'compensations-corrections-export-record-changes',
  COMPENSATIONS_CORRECTIONS_OPEN = 'compensations-corrections-open',
  COMPENSATIONS_DEPARTMENT_ADVANCE_STATUS = 'compensations-department-advance-status',

  COMPENSATIONS_DEPARTMENT_PAYMENT_STATUS = 'compensations-department-payment-status',
  COMPENSATIONS_DETAIL = 'compensations-detail',
  COMPENSATIONS_DETAILS = 'compensations-details',
  COMPENSATIONS_FOR_MONTH_STATUS = 'compensations-for-month-status',
  COMPENSATIONS_IS_CONFIRMED = 'compensations-is-confirmed',
  COMPENSATIONS_MONTHS = 'compensations-months',

  COMPENSATIONS_OVERVIEW = 'compensations-overview',

  COMPENSATIONS_OVERVIEW_DEPARTMENT = 'compensations-overview-department',
  COMPENSATIONS_OVERVIEW_PAYROLL = 'compensations-overview-payroll',
  COMPENSATIONS_PAYMENTS = 'compensations-payments',
  COMPENSATIONS_SALARY_STATUS = 'compensations-salary-status',
  COMPENSATIONS_STATUS = 'compensations-status',
  COUNTRIES = 'countries',
  CUSTOMER_ABSENCES = 'customer-absences',
  CUSTOMER_ACTIVE_SUBSCRIPTION = 'customer-active-subscription',
  CUSTOMER_ACTIVE_SUBSCRIPTION_INVOICES = 'customer-active-subscription-invoices',
  CUSTOMER_DETAIL = 'customer-details',
  CUSTOMER_DOCUMENTS = 'customer-documents',
  CUSTOMER_PLANNING_DAY = 'customer-planning-day',
  CUSTOMER_PLANNING_WEEK = 'customer-planning-week',
  CUSTOMER_RECOMMENDED_SUBSCRIPTION = 'customer-recommended-subscription',

  CUSTOMER_REFUSED_MATCH_INDEX = 'customer-refused-match-index',
  CUSTOMER_REGULAR_EMPLOYEES = 'customer-regular-employees',
  CUSTOMER_SUBSCRIPTIONS = 'customer-subscriptions',
  CUSTOMER_TERMS_OF_SERVICES = 'customer-terms-of-services',
  CUSTOMER_TODO_INDEX = 'customer-todo-index',
  CUSTOMER_WORK_STATION = 'customer-work-station',
  CUSTOMERS_COUNT = 'customers-count',
  CUSTOMERS_INDEX = 'customers-index',

  CUSTOMERS_THREAD_DETAIL = 'customers-thread-detail',
  DASHBOARD_KPIS = 'dashboard-kpis',

  DEBTOR_CASES_COUNT = 'debtors-cases-count',

  DEBTOR_CLOSED_CASES = 'debtors-closed-cases',

  DEBTOR_CUSTOMER_DETAIL = 'debtor_customer_detail',
  DEBTOR_EMPLOYEE_DETAIL = 'debtor_employee_detail',

  DEBTOR_NEW_CASES = 'debtors-new-cases',
  DEBTORS_EMPLOYEE_CASES = 'debtors-employee-cases',

  DEBTORS_OPEN_CASES = 'debtors-open-cases',

  DEPARTMENT_MERGE_ACTION_INDEX = 'department-merge-action-index',
  DEPARTMENT_MERGE_INDEX = 'department-merge-index',

  DEPARTMENTS = 'departments',
  DISTRICTS = 'districts',

  EMPLOYEE_ABSENCE_COUNTER_INDEX = 'employee-absence-counter-index',
  EMPLOYEE_ABSENCE_DETAIL = 'employee-absence-detail',
  EMPLOYEE_ABSENCE_ILLNESS_DETAIL = 'employee-absences-illness-detail',
  EMPLOYEE_ABSENCE_ILLNESS_INDEX = 'employee-absences-illness-index',
  EMPLOYEE_ABSENCE_INDEX = 'employee-absence-index',
  EMPLOYEE_ABSENCE_PREGNANCY_DETAIL = 'employee-absences-pregnancy-detail',
  EMPLOYEE_ABSENCE_PREGNANCY_INDEX = 'employee-absences-pregnancy-index',
  EMPLOYEE_ABSENCE_TODAY = 'employee-absences-today',
  EMPLOYEE_ADVANCE_PAYMENT_REJECTIONS_INDEX = 'employee-advance-payment-rejections-index',
  EMPLOYEE_CALLS = 'employee-calls',
  EMPLOYEE_CONTRACT_DETAIL = 'employee-contract-detail',
  EMPLOYEE_CONTRACT_INDEX = 'employee-contract-index',

  EMPLOYEE_CONTRACT_TIMETABLE_DETAIL = 'employee-contract-timetable-detail',
  EMPLOYEE_DEPARTMENTS = 'employee-departments',

  EMPLOYEE_DETAIL = 'employee-detail',
  EMPLOYEE_DIMONA_DECLARATION_INDEX = 'employee-dimona-declaration-index',
  EMPLOYEE_DOCUMENTS = 'employee-documents',
  EMPLOYEE_ECONOMIC_UNEMPLOYMENT_ACTIVATION_DATES = 'employee-economic-unemployment-activation-dates',
  EMPLOYEE_ECONOMIC_UNEMPLOYMENT_PERIOD = 'employee-economic-unemployment-period',
  EMPLOYEE_ECONOMIC_UNEMPLOYMENT_REPLACES_FIRST = 'employee-economic-unemployment-replaces-first',
  EMPLOYEE_IS_FIRST_ABSENCE_OF_THE_MONTH = 'employee-is-first-absence-of-the-month',
  EMPLOYEE_MANDATORY_WORK = 'employee-mandatory-work',
  EMPLOYEE_PERFORMANCE_OVERVIEW = 'employee-performance-overview',
  EMPLOYEE_PLANNING_CALENDAR = 'employee-planning-calendar',
  EMPLOYEE_PLANNING_DAY = 'employee-planning-day',
  EMPLOYEE_PLANNING_DETAIL = 'employee-planning-detail',

  EMPLOYEE_PLANNING_MONTH = 'employee-planning-month',
  EMPLOYEE_PLANNING_WEEK = 'employee-planning-week',

  EMPLOYEE_PLUXEE_LOGIN = 'employee-pluxee-login',

  EMPLOYEE_REFERRAL_PREMIUMS = 'employee-referral-premiums',

  EMPLOYEE_RESIDENCE_PERMIT = 'employee-residence-permit',
  EMPLOYEE_SALARY_CONFIRMED = 'employee-salary-confirmed',
  EMPLOYEE_TAX_WITHHOLDING = 'employee-tax-withholding',
  EMPLOYEE_THREAD_DETAIL = 'employee-thread-detail',
  EMPLOYEE_TODO_INDEX = 'employee-todo-index',
  EMPLOYEE_WAGE = 'employee-wage',
  EMPLOYEE_WAGE_BENEFIT_DETAIL = 'employee-wage-benefit-detail',
  EMPLOYEE_WAGE_BENEFIT_INDEX = 'employee-wage-benefit-index',
  EMPLOYEE_WORK_PERMIT = 'employee-work-permit',
  EMPLOYEES_COUNT = 'employees-count',
  EMPLOYEES_INDEX = 'employees',
  FAQ = 'faq',
  FILES = 'files',
  GLOBAL_SEARCH = 'global-search',
  INFO_BUBBLES = 'info-bubbles',
  LOGBOOK = 'logbook',
  MATCH_RESERVATION_INDEX = 'match-reservation-index',
  MATCH_SUGGESTIONS_FOR_CONTRACT = 'match-suggestions-for-contract',
  MATCH_SUGGESTIONS_FOR_PLANNING = 'match-suggestions-for-planning',
  MATCHING_UNSATISFIED_CUSTOMER_INDEX = 'matching-unsatisfied-customer-index',
  MATCHE_SUGGESTIONS_FOR_EMPLOYEES_INDEX = 'possible-matches-for-employee-index',
  MATCHING_OVERVIEW = 'matching-overview',
  NEWS_DETAIL = 'news-detail',
  NEWS_IMAGE = 'news-image',
  NEWS_INDEX = 'news-index',
  NOTES = 'notes',
  NOTES_COUNT = 'notes_count',

  OFFICE_DETAIL = 'office-detail',
  OFFICES = 'offices',
  ORIGIN_COMPANY = 'origin-company',

  ORIGIN_COMPANY_COUNT = 'origin-company-count',
  ORIGIN_COMPANY_CUSTOMERS = 'origin-company-customers',

  ORIGIN_COMPANY_DETAIL = 'origin-company-detail',

  ORIGIN_COMPANY_EMPLOYEES = 'origin-company-employees',

  ORIGIN_COMPANY_INDEX = 'origin-company-index',
  PAPER_CHECKS = 'paper-checks',

  PAPER_CHEQUES = 'paper-cheques',
  PAYROLL_MERGE_ACTION_INDEX = 'payroll-merge-action-index',

  PAYROLL_MERGE_INDEX = 'payroll-merge-index',
  PAYROLLS = 'payrolls',
  PERFORMANCE_SHORTAGES = 'performance-shortages',

  PERMISSIONS = 'permissions',
  PLANNING_MATCHES = 'planning-matches',
  PLANNING_REMARKS = 'planning-remarks',
  PLUXEE_LOGIN = 'pluxee-login',
  POSSIBLE_MATCHES_FOR_CUSTOMER_INDEX = 'possible-matches-for-customer-index',
  PUBLIC_CUSTOMER_CHECKLIST = 'public-customer-checklist',
  REFERRAL_PREMIUMS = 'referral-premiums',
  REFERRAL_PREMIUMS_COUNT = 'referral-premiums-count',
  REFERRAL_PREMIUMS_DETAIL = 'referral-premiums-detail',
  REGULAR_CUSTOMERS = 'regular-customers',
  REGULAR_CUSTOMERS_PLANNING = 'regular-customers-planning',
  REMITTANCE = 'remittance',
  REMITTANCE_DETAIL = 'remittance-detail',
  SCANS = 'scans',
  SETTING_ECONOMIC_UNEMPLOYMENT_PERIOD = 'settings-economic-unemployment-period',

  SETTING_PARTENA_ERP_FILES = 'settings-partena-erp-files',
  SETTING_PARTENA_RECORDS = 'settings-partena-records',
  SETTING_PARTENA_WARNINGS = 'settings-partena-warnings',
  SETTINGS_ACTIVE_JOB = 'settings-active-job',
  SETTINGS_ARCHIVED_JOB = 'settings-archived-job',

  SETTINGS_COMPENSATION_HOLIDAYS_DETAIL = 'settings-compensation-holidays-detail',
  SETTINGS_CRON_JOB = 'settings-cron-job',

  SETTINGS_PARTENA_ERP_FILES_DETAIL = 'settings-partena-erp-files-detail',
  SETTINGS_PARTENA_TIME_FILES = 'settings-partena-time-files',

  SETTINGS_PARTENA_TIME_FILES_DETAIL = 'settings-partena-time-files-detail',
  SETTINGS_PARTENA_WAGE_FILES = 'settings-partena-wage-files',
  SETTINGS_PARTENA_WAGE_FILES_DETAIL = 'settings-partena-wage-files-detail',
  SETTINGS_RSZ_DECLARATIONS = 'settings-rsz-declarations',

  SETTINGS_RSZ_NOTIFICATION = 'settings-rsz-notification',
  SETTINGS_RSZ_NOTIFICATIONS_DETAIL = 'rsz-settings-notifications-detail',
  SETTINGS_RSZ_VALIDATION_BOOK = 'settings-rsz-validation-book',

  SETTINGS_SUBSIDIES = 'settings-subsidies',
  SETTINGS_SYNCHRONISATION_DIMONA_DECLARATIONS = 'settings-synchronisation-dimona-declarations',
  SETTINGS_TERMS_OF_SERVICE = 'settings-terms-of-service',

  STAFF_DETAIL = 'staff_detail',

  STAFF_INDEX = 'staff_index',
  SUBSCRIPTION_INVOICES = 'subscription-invoices',
  SUBSCRIPTIONS = 'subscriptions',

  SUBSIDIES = 'subsidies',

  SUBSIDIES_DETAIL = 'subsidies-detail',

  TAX_WITHHOLDING = 'tax-withholding',

  TODO_CLOSED_INDEX = 'todo-closed-index',

  TODO_FOLLOW_UP_INDEX = 'todo-follow-up-index',

  TODO_INDEX = 'todo-index',

  TODO_OPEN_INDEX = 'todo-open-index',
  UNCONFIRMED_SCANS = 'unconfirmed-scans',

  USER_DEPARTMENTS = 'user_departments',

  USER_ROLE = 'user-role',
}

export interface QueryKeys {
  // TODO remove MaybeRefOrGetter

  [QueryKey.ABSENCE_CODES]: void
  [QueryKey.ACCIDENTS_COUNT]: void
  [QueryKey.ACCIDENTS_DETAIL]: { accidentUuid: MaybeRefOrGetter<string> }
  [QueryKey.ACCIDENTS_INDEX]: Ref<PaginationOptions<AccidentIndexFilter>>

  [QueryKey.ACTUAL_MATCHES_FOR_CUSTOMER_INDEX]: Ref<PaginationOptions<MatchFilter>>
  [QueryKey.ANOMALIES_EXCESS]: {
    date: MaybeRefOrGetter<Date>
    employeeUuid: MaybeRefOrGetter<string>
  }
  [QueryKey.ANOMALIES_EXCESS]: {
    date: MaybeRefOrGetter<Date>
    employeeUuid: MaybeRefOrGetter<string>
  }

  [QueryKey.ANOMALIES_TO_VALIDATE]: {
    paginationOptions: Ref<PaginationOptions<AnomaliesToValidateTableFilter>>
  }

  [QueryKey.BENEFIT_CODE]: void
  [QueryKey.CLAIMS_COUNT]: void
  [QueryKey.CLAIMS_DETAIL]: { claimUuid: MaybeRefOrGetter<string> }

  [QueryKey.CLAIMS_INDEX]: Ref<PaginationOptions<ClaimFilter>>
  [QueryKey.COMPENSATIONS_CALCULATED_DETAIL]: {
    compensationUuid: MaybeRefOrGetter<string>
  }
  [QueryKey.COMPENSATIONS_DETAIL]: {
    compensationUuid: MaybeRefOrGetter<string>
  }

  [QueryKey.COMPENSATIONS_IS_CONFIRMED]: {
    date: MaybeRefOrGetter<Date>
    employeeUuid: MaybeRefOrGetter<string>
  }

  [QueryKey.COMPENSATIONS_SALARY_STATUS]: {
    employeeUuid: MaybeRefOrGetter<string>
    today: MaybeRefOrGetter<Date>
  }
  [QueryKey.CUSTOMER_ABSENCES]: {
    customerUuid: MaybeRefOrGetter<string>
    date: MaybeRefOrGetter<Date>
  }
  [QueryKey.CUSTOMER_DETAIL]: { customerUuid: MaybeRefOrGetter<string> }

  [QueryKey.CUSTOMER_PLANNING_WEEK]: {
    customerUuid: MaybeRefOrGetter<string>
    date: MaybeRefOrGetter<Date>
  }

  [QueryKey.CUSTOMER_REFUSED_MATCH_INDEX]: ComputedRef<PaginationOptions<CustomerRefusedMatchIndexFilters>>

  [QueryKey.CUSTOMER_REGULAR_EMPLOYEES]: ComputedRef<string>

  [QueryKey.CUSTOMER_TERMS_OF_SERVICES]: {
    customerUuid: MaybeRefOrGetter<string>
    paginationOptions: Ref<PaginationOptions<CustomerTermsOfServiceFilter>>
  }
  [QueryKey.CUSTOMER_TODO_INDEX]: {
    customerUuid: MaybeRefOrGetter<string>
    paginationOptions: Ref<PaginationOptions<TodoIndexFilter>>
  }

  [QueryKey.CUSTOMER_WORK_STATION]: {
    customerUuid: MaybeRefOrGetter<string>
  }
  [QueryKey.CUSTOMERS_COUNT]: void
  [QueryKey.CUSTOMERS_INDEX]: Ref<PaginationOptions<CustomerIndexFilter>>

  [QueryKey.DEPARTMENT_MERGE_INDEX]: {
    paginationOptions: Ref<PaginationOptions<OriginCompanyCustomerIndexFilter>>
  }

  [QueryKey.DEPARTMENTS]: void

  [QueryKey.EMPLOYEE_ABSENCE_COUNTER_INDEX]: {
    employeeUuid: MaybeRefOrGetter<string>
  }

  [QueryKey.EMPLOYEE_ABSENCE_DETAIL]: {
    absenceUuid: MaybeRefOrGetter<string>
    employeeUuid: MaybeRefOrGetter<string>
  }

  [QueryKey.EMPLOYEE_ABSENCE_ILLNESS_DETAIL]: {
    absenceUuid: MaybeRefOrGetter<string>
    employeeUuid: MaybeRefOrGetter<string>
  }

  [QueryKey.EMPLOYEE_ABSENCE_ILLNESS_INDEX]: {
    employeeUuid: MaybeRefOrGetter<string>
    paginationOptions: Ref<PaginationOptions<AccidentIndexFilter>>
  }
  [QueryKey.EMPLOYEE_ABSENCE_INDEX]: {
    employeeUuid: MaybeRefOrGetter<string>
    paginationOptions: Ref<PaginationOptions<AccidentIndexFilter>>
  }
  [QueryKey.EMPLOYEE_ABSENCE_PREGNANCY_DETAIL]: {
    employeeUuid: MaybeRefOrGetter<string>
    pregnancyUuid: MaybeRefOrGetter<string>
  }
  [QueryKey.EMPLOYEE_ABSENCE_PREGNANCY_INDEX]: {
    employeeUuid: MaybeRefOrGetter<string>
    paginationOptions: Ref<PaginationOptions<AccidentIndexFilter>>
  }

  [QueryKey.EMPLOYEE_ADVANCE_PAYMENT_REJECTIONS_INDEX]: {
    employeeUuid: MaybeRefOrGetter<string>
  }

  [QueryKey.EMPLOYEE_CALLS]: {
    employeeCallType?: MaybeRefOrGetter<string>
    employeeUuid: MaybeRefOrGetter<string>
  }

  [QueryKey.EMPLOYEE_CONTRACT_DETAIL]: {
    employeeUuid: MaybeRefOrGetter<string>
  }

  [QueryKey.EMPLOYEE_CONTRACT_INDEX]: {
    employeeUuid: MaybeRefOrGetter<string>
  }

  [QueryKey.EMPLOYEE_DEPARTMENTS]: {
    employeeUuid: MaybeRefOrGetter<string>
  }

  [QueryKey.EMPLOYEE_DETAIL]: {
    employeeUuid: MaybeRefOrGetter<string>
  }

  [QueryKey.EMPLOYEE_DIMONA_DECLARATION_INDEX]: {
    contractUuid: MaybeRefOrGetter<string>
    paginationOptions: Ref<PaginationOptions<EmployeeDimonaDeclarationIndexFilters>>
  }

  [QueryKey.EMPLOYEE_ECONOMIC_UNEMPLOYMENT_PERIOD]: {
    employeeUuid: MaybeRefOrGetter<string>
    paginationOptions: Ref<PaginationOptions<EmployeeEUFilter>>
  }

  [QueryKey.EMPLOYEE_MANDATORY_WORK]: {
    date: MaybeRefOrGetter<Date>
    employeeUuid: MaybeRefOrGetter<string>
  }

  [QueryKey.EMPLOYEE_PLANNING_CALENDAR]: {
    calendarViewType: MaybeRefOrGetter<CalendarViewType>
    date: MaybeRefOrGetter<Date>
    employeeUuid: MaybeRefOrGetter<string>
  }

  [QueryKey.EMPLOYEE_PLANNING_DETAIL]: {
    eventDate: Ref<string>
    employeeUuid: Ref<string>
    eventUuid: Ref<string>
  }

  [QueryKey.EMPLOYEE_PLANNING_MONTH]: {
    dateString: MaybeRefOrGetter<string>
    employeeUuid: MaybeRefOrGetter<string>
  }

  [QueryKey.EMPLOYEE_PLANNING_WEEK]: {
    date: MaybeRefOrGetter<Date>
    employeeUuid: MaybeRefOrGetter<string>
  }

  [QueryKey.EMPLOYEE_PLUXEE_LOGIN]: {
    employeeUuid: MaybeRefOrGetter<string>
  }

  [QueryKey.EMPLOYEE_RESIDENCE_PERMIT]: {
    employeeUuid: MaybeRefOrGetter<string>
  }

  [QueryKey.EMPLOYEE_TAX_WITHHOLDING]: {
    employeeUuid: MaybeRefOrGetter<string>
  }

  [QueryKey.EMPLOYEE_TODO_INDEX]: {
    employeeUuid: MaybeRefOrGetter<string>
    paginationOptions: Ref<PaginationOptions<TodoIndexFilter>>
  }

  [QueryKey.EMPLOYEE_WAGE]: {
    employeeUuid: MaybeRefOrGetter<string>
  }

  [QueryKey.EMPLOYEE_WAGE_BENEFIT_DETAIL]: {
    benefitCodeUuid: MaybeRefOrGetter<string>
    employeeUuid: MaybeRefOrGetter<string>
  }

  [QueryKey.EMPLOYEE_WAGE_BENEFIT_INDEX]: {
    employeeUuid: MaybeRefOrGetter<string>
    paginationOptions: Ref<PaginationOptions<EmployeeWageBenefitFilter>>
  }

  [QueryKey.EMPLOYEE_WORK_PERMIT]: {
    employeeUuid: MaybeRefOrGetter<string>
  }

  [QueryKey.EMPLOYEES_COUNT]: void

  [QueryKey.EMPLOYEES_INDEX]: Ref<PaginationOptions<EmployeeFilter>>

  [QueryKey.MATCH_RESERVATION_INDEX]: {
    paginationOptions: ComputedRef<PaginationOptions<MatchingReservationFilter>>
  }

  [QueryKey.MATCHE_SUGGESTIONS_FOR_EMPLOYEES_INDEX]: Ref<PaginationOptions<MatchFilter>>

  [QueryKey.NEWS_DETAIL]: {
    newsArticleUuid: MaybeRefOrGetter<string>
  }

  [QueryKey.NEWS_INDEX]: {
    paginationOptions: Ref<PaginationOptions<NewsFilter>>
  }

  [QueryKey.NOTES]: {
    entityType: MaybeRefOrGetter<string>
    entityUuid: MaybeRefOrGetter<string>
    paginationOptions: Ref<PaginationOptions<NoteFilters>>
  }
  [QueryKey.NOTES_COUNT]: {
    entityType: MaybeRefOrGetter<string>
    entityUuid: MaybeRefOrGetter<string>
  }

  [QueryKey.ORIGIN_COMPANY_COUNT]: void

  [QueryKey.ORIGIN_COMPANY_CUSTOMERS]: {
    originCompanyUuid: MaybeRefOrGetter<string>
    paginationOptions: Ref<PaginationOptions<OriginCompanyCustomerIndexFilter>>
  }

  [QueryKey.ORIGIN_COMPANY_DETAIL]: {
    originCompanyUuid: MaybeRefOrGetter<string>
  }

  [QueryKey.ORIGIN_COMPANY_EMPLOYEES]: {
    originCompanyUuid: MaybeRefOrGetter<string>
    paginationOptions: Ref<PaginationOptions<OriginCompanyEmployeeIndexFilter>>
  }

  [QueryKey.ORIGIN_COMPANY_INDEX]: Ref<PaginationOptions<OriginCompanyFilter>>

  [QueryKey.PAYROLL_MERGE_INDEX]: {
    paginationOptions: Ref<PaginationOptions<PayrollMergeIndexFilter>>
  }
  [QueryKey.PERMISSIONS]: void

  [QueryKey.POSSIBLE_MATCHES_FOR_CUSTOMER_INDEX]: Ref<PaginationOptions<MatchFilter>>
  [QueryKey.PUBLIC_CUSTOMER_CHECKLIST]: {
    customerUuid: MaybeRefOrGetter<string>
    secret: MaybeRefOrGetter<string>
  }
  [QueryKey.SETTINGS_ACTIVE_JOB]: ComputedRef<PaginationOptions<JobsIndexFilters>>
  [QueryKey.STAFF_DETAIL]: Ref<string>

  [QueryKey.STAFF_INDEX]: Ref<PaginationOptions<StaffFilter>>

  [QueryKey.TODO_INDEX]: {
    paginationOptions: Ref<PaginationOptions<TodoIndexFilter>>
  }

  [QueryKey.USER_DEPARTMENTS]: void

  [QueryKey.USER_ROLE]: void
}
