import type { RouteRecordRaw } from 'vue-router'

import ROUTE_NAME from '@/router/routeName'

export const AuthenticationRoutes: RouteRecordRaw[] = [
  {
    name: ROUTE_NAME.AUTH.LOGIN,
    path: '/login',
    component: () => import('@/modules/authentication/views/AuthLoginView.vue'),
    meta: { requiresAuth: false },
  },
  {
    name: ROUTE_NAME.AUTH.AD_CALLBACK,
    path: '/auth-ad',
    component: () => import('@/modules/authentication/views/AuthAdCallbackView.vue'),
    meta: { requiresAuth: false },
  },
  {
    name: ROUTE_NAME.AUTH.LOGOUT,
    path: '/logout',
    component: () => import('@/modules/authentication/views/AuthLogoutView.vue'),
    meta: { requiresAuth: false },
  },
  {
    name: ROUTE_NAME.AUTH.MAINTENANCE,
    path: '/maintenance',
    component: () => import('@/modules/authentication/views/AuthMaintenanceModeView.vue'),
    meta: { requiresAuth: false },
  },
  {
    name: ROUTE_NAME.AUTH.NO_PERMISSION,
    path: '/no-permission',
    component: () => import('@/modules/authentication/views/AuthNoPermissionView.vue'),
    meta: { requiresAuth: false },
  },
]
