import type { RouteRecordRaw } from 'vue-router'

import { Permission } from '@/constants/permission.enum'
import { permissionMiddleware } from '@/middleware/permission.middleware'
import ROUTE_NAME from '@/router/routeName'

export const debtorsRoutes: RouteRecordRaw[] = [
  {
    name: ROUTE_NAME.DEBTORS.INDEX,
    beforeEnter: permissionMiddleware([
      Permission.DEBTOR_READ,
    ]),
    path: '/debtors',
    component: () => import('@/modules/debtors/views/DebtorsView.vue'),
    redirect: { name: ROUTE_NAME.DEBTORS.CUSTOMERS.INDEX },
    children: [
      {
        name: ROUTE_NAME.DEBTORS.EMPLOYEES.INDEX,
        path: 'employees',
        component: () => import('@/modules/debtors/views/employees/DebtorsEmployeeCasesView.vue'),
      },
      {
        name: ROUTE_NAME.DEBTORS.CUSTOMERS.INDEX,
        path: 'customers',
        component: () => import('@/modules/debtors/views/customers/DebtorsCustomerCasesView.vue'),
        redirect: { name: ROUTE_NAME.DEBTORS.CUSTOMERS.NEW },
        children: [
          {
            name: ROUTE_NAME.DEBTORS.CUSTOMERS.NEW,
            path: 'new',
            component: () => import('@/modules/debtors/views/customers/DebtorsCustomerNewCasesView.vue'),
          },
          {
            name: ROUTE_NAME.DEBTORS.CUSTOMERS.OPEN,
            path: 'open',
            component: () => import('@/modules/debtors/views/customers/DebtorsCustomerOpenCasesView.vue'),
          },
          {
            name: ROUTE_NAME.DEBTORS.CUSTOMERS.CLOSED,
            path: 'closed',
            component: () => import('@/modules/debtors/views/customers/DebtorsCustomerClosedCasesView.vue'),
          },
        ],
      },
    ],
  },
  {
    name: ROUTE_NAME.DEBTORS.CUSTOMERS.CASE_DETAIL,
    beforeEnter: permissionMiddleware([
      Permission.DEBTOR_READ,
    ]),
    path: '/debtors/customer/case/:caseUuid',
    component: () => import('@/modules/debtors/views/customers/DebtorsCustomerCasesDetailView.vue'),
  },
  {
    name: ROUTE_NAME.DEBTORS.CUSTOMERS.CUSTOMER_DETAIL,
    beforeEnter: permissionMiddleware([
      Permission.DEBTOR_READ,
    ]),
    path: '/debtors/customer/precase/:customerUuid',
    component: () => import('@/modules/debtors/views/customers/DebtorsCustomerCasesDetailView.vue'),
  },
  {
    name: ROUTE_NAME.DEBTORS.EMPLOYEES.DETAIL,
    beforeEnter: permissionMiddleware([
      Permission.DEBTOR_READ,
    ]),
    path: '/debtors/employees/:employeeUuid',
    component: () => import('@/modules/debtors/views/employees/DebtorsEmployeeCasesDetailView.vue'),
  },
]
