import type { RouteRecordRaw } from 'vue-router'

import { Permission } from '@/constants/permission.enum'
import { permissionMiddleware } from '@/middleware/permission.middleware'
import ROUTE_NAME from '@/router/routeName'

export const matchingRoutes: RouteRecordRaw[] = [
  {
    name: ROUTE_NAME.MATCHING_CENTER.INDEX,
    beforeEnter: permissionMiddleware([
      Permission.MATCHING_CENTER_READ,
    ]),
    path: '/matching-center',
    component: () => import('@/modules/matching/views/MatchingView.vue'),
    redirect: { name: ROUTE_NAME.MATCHING_CENTER.EMPLOYEES },
    children: [
      {
        name: ROUTE_NAME.MATCHING_CENTER.EMPLOYEES,
        path: 'employees',
        component: () => import('@/modules/matching/views/MatchingEmployeesView.vue'),
      },
      {
        name: ROUTE_NAME.MATCHING_CENTER.CUSTOMERS,
        path: 'customers',
        component: () => import('@/modules/matching/views/MatchingCustomersView.vue'),
      },
      {
        name: ROUTE_NAME.MATCHING_CENTER.RESERVATIONS,
        path: 'reservations',
        component: () => import('@/modules/matching/views/MatchingReservationsView.vue'),
      },
      {
        name: ROUTE_NAME.MATCHING_CENTER.UNSATISFIED_CUSTOMERS,
        path: 'unsatisfied-customers',
        component: () => import('@/modules/matching/views/MatchingUnsatisfiedCustomersView.vue'),
      },
    ],
  },
]
