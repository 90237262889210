import type { RouteRecordRaw } from 'vue-router'

import { Permission } from '@/constants/permission.enum'
import { permissionMiddleware } from '@/middleware/permission.middleware'
import ROUTE_NAME from '@/router/routeName'

export const logbooksRoutes: RouteRecordRaw[] = [
  {
    name: ROUTE_NAME.LOGBOOKS.INDEX,
    beforeEnter: permissionMiddleware([
      Permission.LOGBOOK_READ,
    ]),
    path: '/logbooks',
    component: () => import('@/modules/logbooks/views/LogbooksView.vue'),
  },
]
