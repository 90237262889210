import type {
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteLocationRaw,
} from 'vue-router'

import { usePermission } from '@/composables/permission.composable'
import type { Permission } from '@/constants/permission.enum.ts'
import ROUTE_NAME from '@/router/routeName'

export function permissionMiddleware(
  permissions: Permission[],
  fallbackRoute?: RouteLocationRaw,
): (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => void {
  const { hasPermission } = usePermission()

  return (_to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext) => {
    if (hasPermission(permissions)) {
      next()
    }
    else {
      next(fallbackRoute ?? { name: ROUTE_NAME.AUTH.NO_PERMISSION })
    }
  }
}
