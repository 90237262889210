import type { RouteRecordRaw } from 'vue-router'

import { Permission } from '@/constants/permission.enum'
import { permissionMiddleware } from '@/middleware/permission.middleware'
import ROUTE_NAME from '@/router/routeName'

export const referralPremiumRoutes: RouteRecordRaw[] = [
  {
    name: ROUTE_NAME.REFERRAL_PREMIUM.INDEX,
    beforeEnter: permissionMiddleware([
      Permission.REFERRAL_PREMIUM_READ,
    ]),
    path: '/referral-premiums',
    component: () => import('@/modules/application-premium/view/ReferralPremiumsView.vue'),
  },
  {
    name: ROUTE_NAME.REFERRAL_PREMIUM.DETAIL,
    beforeEnter: permissionMiddleware([
      Permission.REFERRAL_PREMIUM_READ,
    ]),
    path: '/referral-premiums/:uuid',
    component: () => import('@/modules/application-premium/view/ReferralPremiumDetailView.vue'),
  },
]
