import type { RouteRecordRaw } from 'vue-router'

import { Permission } from '@/constants/permission.enum'
import { permissionMiddleware } from '@/middleware/permission.middleware'
import ROUTE_NAME from '@/router/routeName'

export const compensationsRoutes: RouteRecordRaw[] = [
  {
    name: ROUTE_NAME.COMPENSATIONS.INDEX,
    beforeEnter: permissionMiddleware([
      Permission.SALARY_READ,
    ]),
    path: '/compensations',
    component: () => import('@/modules/compensations/views/CompensationsView.vue'),
    redirect: { name: ROUTE_NAME.COMPENSATIONS.ADVANCES.INDEX },
    children: [
      {
        name: ROUTE_NAME.COMPENSATIONS.ADVANCES.INDEX,
        path: 'advances',
        redirect: { name: ROUTE_NAME.COMPENSATIONS.ADVANCES.STAFF },
        children: [
          {
            name: ROUTE_NAME.COMPENSATIONS.ADVANCES.STAFF,
            path: 'staff',
            component: () => import('@/modules/compensations/views/advances/CompensationsAdvancesStaffView.vue'),
          },
          {
            name: ROUTE_NAME.COMPENSATIONS.ADVANCES.ADMIN,
            beforeEnter: permissionMiddleware([
              Permission.ADMIN,
            ]),
            path: 'admin',
            component: () => import('@/modules/compensations/views/advances/CompensationsAdvancesAdminView.vue'),
          },
        ],
      },
      {
        name: ROUTE_NAME.COMPENSATIONS.PAYMENTS.INDEX,
        path: 'payments',
        redirect: { name: ROUTE_NAME.COMPENSATIONS.PAYMENTS.STAFF },
        children: [
          {
            name: ROUTE_NAME.COMPENSATIONS.PAYMENTS.STAFF,
            path: 'staff',
            component: () => import('@/modules/compensations/views/payments/staff/CompensationsPaymentsStaffView.vue'),
          },
          {
            name: ROUTE_NAME.COMPENSATIONS.PAYMENTS.ADMIN,
            beforeEnter: permissionMiddleware([
              Permission.ADMIN,
            ]),
            path: 'admin',
            component: () => import('@/modules/compensations/views/payments/admin/CompensationsPaymentsAdminView.vue'),
          },
        ],
      },
      {
        name: ROUTE_NAME.COMPENSATIONS.CORRECTIONS.INDEX,
        path: 'corrections',
        component: () => import('@/modules/compensations/views/corrections/CompensationCorrectionView.vue'),
        redirect: {
          name: ROUTE_NAME.COMPENSATIONS.CORRECTIONS.IN_PROGRESS.INDEX,
        },
        children: [
          {
            name: ROUTE_NAME.COMPENSATIONS.CORRECTIONS.IN_PROGRESS.INDEX,
            path: 'in-progress',
            component: () =>
              import('@/modules/compensations/views/corrections/CompensationCorrectionInProgressIndexView.vue'),
          },
          {
            name: ROUTE_NAME.COMPENSATIONS.CORRECTIONS.REQUESTED.INDEX,
            path: 'requested',
            component: () =>
              import('@/modules/compensations/views/corrections/CompensationCorrectionRequestedIndexView.vue'),
          },
          {
            name: ROUTE_NAME.COMPENSATIONS.CORRECTIONS.APPROVED.INDEX,
            path: 'approved',
            component: () =>
              import('@/modules/compensations/views/corrections/CompensationCorrectionApprovedIndexView.vue'),
          },
          {
            name: ROUTE_NAME.COMPENSATIONS.CORRECTIONS.EXPORTED.INDEX,
            path: 'exported',
            component: () =>
              import('@/modules/compensations/views/corrections/CompensationCorrectionExportedIndexView.vue'),
          },
          {
            name: ROUTE_NAME.COMPENSATIONS.CORRECTIONS.REJECTED.INDEX,
            path: 'rejected',
            component: () =>
              import('@/modules/compensations/views/corrections/CompensationCorrectionRejectedIndexView.vue'),
          },
        ],
      },
    ],
  },
  {
    name: ROUTE_NAME.COMPENSATIONS.ADVANCES.DETAIL,
    beforeEnter: permissionMiddleware([
      Permission.SALARY_READ,
    ]),
    path: '/compensations/advances/:employeeUuid',
    component: () => import('@/modules/compensations/views/advances/CompensationsAdvancesDetailView.vue'),
  },
  {
    name: ROUTE_NAME.COMPENSATIONS.PAYMENTS.DETAIL,
    beforeEnter: permissionMiddleware([
      Permission.SALARY_READ,
    ]),
    path: '/compensations/payments/:employeeUuid/:compensationUuid',
    component: () => import('@/modules/compensations/views/payments/CompensationsPaymentsDetailView.vue'),
  },
  {
    name: ROUTE_NAME.COMPENSATIONS.CORRECTIONS.IN_PROGRESS.DETAIL,
    beforeEnter: permissionMiddleware([
      Permission.SALARY_READ,
    ]),
    path: '/compensations/correction/:employeeUuid/in-progress/:compensationUuid',
    component: () =>
      import('@/modules/compensations/views/corrections/detail/CompensationCorrectionProgressDetailView.vue'),
  },
  {
    name: ROUTE_NAME.COMPENSATIONS.CORRECTIONS.EXPORTED.DETAIL,
    beforeEnter: permissionMiddleware([
      Permission.SALARY_READ,
    ]),
    path: '/compensations/correction/:employeeUuid/exported/:compensationUuid/:exportUuid',
    component: () =>
      import('@/modules/compensations/views/corrections/detail/CompensationCorrectionExportedDetailView.vue'),
  },
  {
    name: ROUTE_NAME.COMPENSATIONS.CORRECTIONS.APPROVED.DETAIL,
    beforeEnter: permissionMiddleware([
      Permission.SALARY_READ,
    ]),
    path: '/compensations/correction/:employeeUuid/approved/:compensationUuid',
    component: () =>
      import('@/modules/compensations/views/corrections/detail/CompensationCorrectionApprovedDetailView.vue'),
  },
  {
    name: ROUTE_NAME.COMPENSATIONS.CORRECTIONS.REQUESTED.DETAIL,
    beforeEnter: permissionMiddleware([
      Permission.SALARY_READ,
    ]),
    path: '/compensations/correction/:employeeUuid/requested/:compensationUuid',
    component: () =>
      import('@/modules/compensations/views/corrections/detail/CompensationCorrectionRequestedDetailView.vue'),
  },
  {
    name: ROUTE_NAME.COMPENSATIONS.CORRECTIONS.REJECTED.DETAIL,
    beforeEnter: permissionMiddleware([
      Permission.SALARY_READ,
    ]),
    path: '/compensations/correction/:employeeUuid/rejected/:compensationUuid',
    component: () =>
      import('@/modules/compensations/views/corrections/detail/CompensationCorrectionRejectedDetailView.vue'),
  },
]
