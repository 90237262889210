import type { RouteRecordRaw } from 'vue-router'

import { Permission } from '@/constants/permission.enum'
import { permissionMiddleware } from '@/middleware/permission.middleware'
import ROUTE_NAME from '@/router/routeName'

export const newsRoutes: RouteRecordRaw[] = [
  {
    name: ROUTE_NAME.NEWS.INDEX,
    beforeEnter: permissionMiddleware([
      Permission.NEWS_READ,
    ]),
    path: 'news',
    component: () => import('@/modules/news/views/NewsIndexView.vue'),
  },
  {
    name: ROUTE_NAME.NEWS.DETAIL,
    beforeEnter: permissionMiddleware([
      Permission.NEWS_READ,
    ]),
    path: 'news/:uuid',
    component: () => import('@/modules/news/views/NewsDetailView.vue'),
  },
  {
    name: ROUTE_NAME.NEWS.EDIT,
    beforeEnter: permissionMiddleware([
      Permission.NEWS_UPDATE,
    ]),
    path: 'news/edit/:uuid?',
    component: () => import('@/modules/news/views/NewsFormView.vue'),
  },
]
