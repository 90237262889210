export const TEST_ID = {
  ACCIDENTS: {
    CREATE: {
      CREATE_BUTTON: 'accidents-overview-create-button',
      CREATE_FORM_MODAL: 'accidents-create-form-modal',
      CUSTOMER_AUTO_COMPLETE: 'accident-form-customer-auto-complete',
      DATE_INPUT: 'accident-form-date-input',
      EMPLOYEE_AUTO_COMPLETE: 'accident-form-employee-auto-complete',
      RADIO_BUTTON_SELECT: 'accident-form-radio-button-select',
      SUBMIT_BUTTON: 'accident-form-submit-button',
      TEXT_INPUT: 'accident-form-text-input',
    },
    OVERVIEW: {
      ACCIDENTS_PAGE: 'accidents-overview-page',
    },
  },
  AUTH: {
    AD_LOGIN_BUTTON: 'auth_ad_login_button',
    ADMIN_LOGIN_BUTTON: 'auth_admin_login_button',
  },
  CLAIMS: {
    CREATE: {
      CUSTOMER_AUTO_COMPLETE: 'claims-create-customer-auto-complete',
      DATE_INPUT: 'claims-create-date-input',
      DESCRIPTION_INPUT: 'claims-create-description-input',
      EMPLOYEE_AUTO_COMPLETE: 'claims-create-employee-auto-complete',
      SUBMIT_BUTTON: 'claims-create-submit-button',
      SUCCESS_MESSAGE: 'claims-create-success-message',
    },
    OVERVIEW: {
      CLAIMS_PAGE: 'claims-overview-page',
      CREATE_BUTTON: 'claims-overview-create-button',
    },
  },
  CUSTOMER: {
    CREATE: {
      CREATE_BUTTON: 'customer_create_button',
      DEPARTMENT_SELECT: 'customer_create_department_select',
      FIRST_NAME_INPUT: 'customer_create_first_name_input',
      LAST_NAME_INPUT: 'customer_create_last_name_input',
      MODAL: 'customer_create_modal',
      SUBMIT_BUTTON: 'customer_create_submit_button',
      SUCCESS_MESSAGE: 'customer_create_success_message',
    },
    OVERVIEW: {
      CUSTOMER_PAGE: 'customer-overview-page',
    },
    PROSPECT: {
      ACTIVATION_STEP: {
        ACTIVATION_STEP: {
          CHECKBOX_GROUP:
            'customer-prospect-activation-step-activation-step-form-checkbox-group',
        },
        AVAILABILITY_STEP: {},
        DESIRED_HOURS_STEP: {
          FORM_INPUT_DAYS: 'customer-prospect-activation-form-input-days',
          FORM_INPUT_HOURS: 'customer-prospect-activation-form-input-hours',
          FORM_INPUT_REMARKS: 'customer-prospect-activation-form-input-remarks',
          FORM_INPUT_REPLACEMENT:
            'customer-prospect-activation-form-input-replacement',
          FREQUENCY_FORM_SELECT:
            'customer-prospect-activation-frequency-form-select',
        },
        TERMS_OF_SERVICE_STEP: {},
      },
      FINANCIAL_STEP: {},
      LEAD_STEP: {},
      NEXT_STEP_BUTTON: 'customer-prospect-next-step-button',
      PAGE: { PROSPECT_PAGE: 'customer-prospect-view-page' },
      PERSONAL_STEP: {
        ADDRESS_ELEMENT: {
          ADDRESS_BUTTON_CLICK:
            'customer-prospect-address-element-domicile-address-button-click',
          ADDRESS_FORM_SEARCH_SELECT:
            'customer-prospect-address-element-domicile-address-form-search-select',
          ADDRESS_INPUT_DATE:
            'customer-prospect-address-element-domicile-address-input-date',
          ADDRESS_MANUAL_INPUT_CHECKBOX:
            'customer-prospect-address-element-domicile-address-manual-input-checkbox',
          ADDRESS_MODAL:
            'customer-prospect-address-element-domicile-address-modal',
          ADDRESS_SAVE_BUTTON:
            'customer-prospect-address-element-domicile-address-save-button',
        },
        CONTACT_ELEMENT: {
          PHONE_NUMBER_INPUT:
            'customer-prospect-personal-step-contact-element-phone-number-input',
          PHONE_SELECT: 'customer-prospect-personal-step-contact-element',
        },
        GENERAL_ELEMENT: {
          SELECT_DEPARTMENT:
            'customer-prospect-personal-step-form-select-department',
        },
        IDENTITY_ELEMENT: {
          CALL_NAME: 'customer-prospect-identity-step-form-call-name',
          INPUT_BIRTHDAY:
            'customer-prospect-identity-step-identity-step-form-input-birthday',
          INPUT_FIRST_NAME:
            'customer-prospect-identity-step-form-input-first-name',
          INPUT_LAST_NAME:
            'customer-prospect-identity-step-form-input-last-name',
          SELECT_GENDER: 'customer-prospect-identity-step-form-select-gender',
          SELECT_LANGUAGE:
            'customer-prospect-identity-step-form-select-language',
        },
        PAYMENT_IDENTITIES_ELEMENT: {},
        PETS_ELEMENT: {
          PETS_CHECKBOX_GROUP:
            'customer-prospect-pets-element-form-pets-checkbox-group',
          PETS_TEXT_INPUT: 'customer-prospect-pets-element-pets-text-input',
        },
      },
      PREVIOUS_STEP_BUTTON: 'customer-prospect-previous-step-button',

      REFERRAL_STEP: {},
      SUBMIT_BUTTON: 'customer-prospect-submit-button',
      SUCCESS_MESSAGE: 'customer-prospect-success-message',
      UPDATE_COMPLETE_STEP: {},
    },
  },
  EMPLOYEES: {
    APPLICATION: {
      EMPLOYEE_SELECT: 'employees_application_employee_select',
      NEXT_STEP_BUTTON: 'employees_application_next_step_button',
      PAGE: 'employees_application_page',
      PREVIOUS_STEP_BUTTON: 'employees_application_previous_step_button',
      SUBMIT_BUTTON: 'employees_application_submit_button',
      SUCCESS_MESSAGE: 'employees_application_success_message',
      CONCLUSION_RADIO_BUTTONS:
        'employees_application_conclusion_radio_buttons',
    },
    COMPENSATION: {
      WAGES: {
        CREATE_BUTTON: 'employees_compensation_wages_create_button',
        DATE_INPUT: 'employees_compensation_wages_date_input',
        REMARKS_INPUT: 'employees_compensation_wages_remarks_input',
        SUBMIT_BUTTON: 'employees_compensation_wages_submit_button',
        SUCCESS_MESSAGE: 'employees_compensation_wages_success_message',
        WAGE_INPUT: 'employees_compensation_wages_wage_input',
      },
    },
    CREATE: {
      DEPARTMENT_SELECT: 'employees_create_department_select',
      FIRST_NAME_INPUT: 'employees_create_first_name_input',
      ID_NUMBER_INPUT: 'employees_create_id_number_input',
      LAST_NAME_INPUT: 'employees_create_last_name_input',
      SUBMIT_BUTTON: 'employees_create_submit_button',
      SUCCESS_MESSAGE: 'employees_create_success_message',
    },
    INDEX: {
      CREATE_BUTTON: 'employees_index_create_button',
    },
  },
  GENERAL: {
    LOADING_BRUSH: 'general_loading_brush',
  },
  NAV_BAR: {
    GREETING_MESSAGE: 'nav_bar_greeting_message',
  },
  SETTINGS: {
    OFFICES: {
      CREATE_BUTTON: 'settings_offices_create_button',
      LANGUAGE_SELECT: 'settings_offices_language_select',
      LIST: 'settings_offices_list',
      NAME_INPUT: 'settings_offices_name_input',
      SAVE_BUTTON: 'settings_offices_save_button',
      STREET_NAME_INPUT: 'settings_offices_street_name_input',
    },
    ORIGIN_COMPANY: {
      NAME_INPUT: 'settings_origin_company_name_input',
      PAYROLLUUID_INPUT: 'settings_origin_company_payrolluuid_input',
      STARTINVOICEPERIODON_INPUT:
        'settings_origin_company_startinvoiceperiodon_input',
      STARTSENDINGOUTINVOICESON_INPUT:
        'settings_origin_company_startsendingoutinvoiceson_input',
      TERMSOFSERVICESENTON_INPUT:
        'settings_origin_company_termsofservicesenton_input',
      TOOKOVERON_INPUT: 'settings_origin_company_tookoveron_input',
    },
  },
  SHARED: {
    // new
    API_ERROR_TOAST: 'shared-api-error-toast',
    CONFIRM_DIALOG: {
      CANCEL_BUTTON: 'shared-confirm-dialog-cancel-button',
      CONFIRM_BUTTON: 'shared-confirm-dialog-confirm-button',
      CONTENT: 'shared-confirm-dialog-content',
    },
    MALFORMED_RESPONSE_TOAST: 'shared-malformed-response-toast',
    SUCCESS_TOAST: 'shared-success-toast',
    // old
    ADDRESS: {
      BUS_INPUT: 'shared_address_street_bus_input',
      CITY_INPUT: 'shared_address_city_input',
      COUNTRY_SELECT: 'shared_address_country_select',
      MAP: 'shared_address_map',
      NUMBER_INPUT: 'shared_address_street_number_input',
      POSTAL_CODE_INPUT: 'shared_address_postal_code_input',
      REGION_SELECT: 'shared_address_region_select',
      REMARKS_INPUT: 'shared_address_remarks_input',
      STREET_INPUT: 'shared_address_street_name_input',
    },
    PHONE_NUMBER: {
      LABEL_SELECT: 'shared_phone_number_label_select',
    },
  },
  TODO: {
    CREATE: {
      ASSIGNED_STAFF_AUTO_COMPLETE: 'todo-create-assigned-staff-select',
      CREATE_BUTTON: 'todo-create-button',
      CREATE_FORM_MODAL: 'todo-create-form-modal',
      CUSTOMER_AUTO_COMPLETE: 'todo-create-customer-auto-complete',
      DEPARTMENT_SELECT: 'todo-create-department-select',
      DESCRIPTION_INPUT: 'todo-create-description-input',
      DUE_DATE_INPUT: 'todo-create-due-date-input',
      EMPLOYEE_AUTO_COMPLETE: 'todo-create-employee-auto-complete',
      MAIN_CATEGORY_SELECT: 'todo-create-main-category-select',
      SUBMIT_BUTTON: 'todo-create-submit-button',
      SUCCESS_MESSAGE: 'todo-create-success-message',
    },
    DELETE: {
      DELETE_BUTTON: 'todo-delete-button',
      SUCCESS_MESSAGE: 'todo-delete-success-message',
    },
    GENERAL: {
      ACTION_MENU_BUTTON: 'todo-general-action-menu-button',
      ITEM: 'todo-general-item',
    },
    OPEN: {
      OPEN_TODO_PAGE: 'open-todo-page',
    },
  },
}
