<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import AppButton from '@/components/app/button/AppButton.vue'
import AppCard from '@/components/app/cards/card/AppCard.vue'
import AppText from '@/components/app/text/AppText.vue'
import FormButtonGroup from '@/components/form/button-group/FormButtonGroup.vue'
import { useLoading } from '@/composables/loading/loading.composable'

const props = defineProps<{
  isOpen: boolean
}>()

const emit = defineEmits<{
  close: []
  success: []
}>()

const { t } = useI18n()

const loadingState = useLoading()

function onCloseButtonClick(): void {
  emit('close')
}

async function onRefreshButtonClick(): Promise<void> {
  loadingState.setLoadingState(true)
  emit('success')
}
</script>

<template>
  <AppCard
    v-if="props.isOpen"
    border-color="zircon"
    class="fixed right-4 top-4 z-[200] !w-[320px]"
    color="white"
    has-shadow
  >
    <AppText
      :value="`${t('shared.new_version_title')} ✨`"
      size="lg"
    />
    <AppText
      :value="t('shared.new_version_description')"
      class="mt-2"
      size="sm"
    />
    <FormButtonGroup
      class="mt-4"
      justify="end"
    >
      <AppButton
        :is-disabled="loadingState.isLoading.value"
        :label="t('shared.close')"
        variant="outline"
        @component:click="onCloseButtonClick"
      />
      <AppButton
        :is-loading="loadingState.isLoading.value"
        :label="t('shared.refresh')"
        @component:click="onRefreshButtonClick"
      />
    </FormButtonGroup>
  </AppCard>
</template>
