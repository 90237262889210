import type { RouteRecordRaw } from 'vue-router'

import { Permission } from '@/constants/permission.enum'
import { permissionMiddleware } from '@/middleware/permission.middleware'
import ROUTE_NAME from '@/router/routeName'

export const accidentsRoutes: RouteRecordRaw[] = [
  {
    name: ROUTE_NAME.ACCIDENTS.INDEX,
    beforeEnter: permissionMiddleware([
      Permission.ACCIDENT_READ,
    ]),
    path: '/accidents',
    component: () => import('@/modules/accidents/views/AccidentOverviewView.vue'),
  },
  {
    name: ROUTE_NAME.ACCIDENTS.DETAILS.INDEX,
    beforeEnter: permissionMiddleware([
      Permission.ACCIDENT_READ,
    ]),
    path: '/accidents/:uuid',
    component: () => import('@/modules/accidents/views/AccidentDetailView.vue'),
    redirect: { name: ROUTE_NAME.ACCIDENTS.DETAILS.GENERAL },
    children: [
      {
        name: ROUTE_NAME.ACCIDENTS.DETAILS.GENERAL,
        path: 'general',
        component: () => import('@/modules/accidents/views/details/AccidentDetailGeneralView.vue'),
      },
      {
        name: ROUTE_NAME.ACCIDENTS.DETAILS.ACCIDENT,
        path: 'accident-and-injury',
        component: () => import('@/modules/accidents/views/details/AccidentDetailAccidentAndInjuryView.vue'),
      },
      {
        name: ROUTE_NAME.ACCIDENTS.DETAILS.ACTIVITY,
        path: 'activity',
        component: () => import('@/modules/accidents/views/details/AccidentDetailActivityView.vue'),
      },
    ],
  },
  {
    name: ROUTE_NAME.ACCIDENTS.REPORT,
    path: '/accidents/:uuid/report',
    component: () => import('@/modules/accidents/views/AccidentReportView.vue'),
  },
]
